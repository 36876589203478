<template>
  <section class="mint-wrapper">
    <div class="container mint-wrapper-inner">
        <div class="c-01-logo">
            <a href="#"><img  :src="require('@/assets/images/C-01-logo.svg')" alt="C-01 Project"></a>
        </div>

        <main v-if="!accountID" class="main-content">
            <section class="mint-content text-center px-30">
                <div class="section-title">
                    <h2>Get your free c-02</h2>
                </div>
                <div class="sub-title">
                    <p>
                        You must have at least 1 C-01
                    </p>
                </div>

                <a
                  href="#" class="theme-btn theme-btn--primary sm-icon wallet-icon icon-right-align icon-border"
                  @click="connectWallet()"
                >
                    CONNECT YOUR WALLET
                </a>
            </section>
        </main>
        <main v-else class="main-content">
            <section class="mint-content text-center px-30">
                <div class="section-title">
                    <h2>Get your free c-02</h2>
                </div>
                <div class="sub-title">
                    <p>
                        YOU CURRENTLY HAVE <strong>{{this.nftCount}} C-01</strong>
                    </p>
                    <p>
                        YOU CAN CLAIM <strong>{{this.claimCount}} C-02</strong>
                    </p>
                </div>

                <a href="#" @click="claim()" v-if="this.claimCount>0" class="theme-btn theme-btn--primary">
                    Claim
                </a>
            </section>
            <section class="mint-content text-center claim-check">
                <h3>NFT CLAIM CHECK</h3>
                <p class="fs-18">Enter the Token ID to see if a C-01 NFT <br> is eligible for a one-time claim.</p>

                <div class="claim-form d-flex flex-wrap">
                    <div class="form-label">
                        <label>C-01 <span>#</span></label>
                    </div>
                    <div class="form-fields">
                        <input type="text" v-model="checkId" class="claim-textbox" name="claim">
                        <input type="submit" @click="check()" class="theme-btn theme-btn--primary" value="CHECK">
                        <br/>
                        <div v-bind:style= "[condition ? {'color':'red','font-weight': 'bold'} : {'color':'#60f260','font-weight': 'bold'}]">{{this.claimable}}</div>
                    </div>
                </div>

                <div class="sm-text italic-text text-center">
                    <p>
                        Please note : this checker updates in real time as tokens are claimed.
                    </p>
                    <p>
                        If you are purchasing a C-01 on the secondary market, keep in mind that it’s possible
                        for someone to claim immediately after you have checked, making the C-01
                        no longer eligible.
                    </p>
                </div>
            </section>
        </main>

        <div class="footer-btns text-center">
            <a href="https://discord.com/invite/c-01" class="theme-btn sm-icon discord-icon icon-left-align pick-icon">
                JOIN DISCORD
            </a>
            <a href="https://twitter.com/C_01_Project" class="theme-btn sm-icon twitter-icon icon-left-align pick-icon">
                JOIN TWITTER
            </a>
        </div>
    </div>
</section>
</template>

<script>
var Web3 = require("web3");
import abis from "../abis/C01.json";
import abisOne from "../abis/C02.json";

export default {
  name: "Mint",
  data() {
    return {
      address: "",
      addressOne:"",
      accountID: "",
      accountBalance: 0,
      abi: [],
      abiOne: [],
      contract: [],
      contractOne: [],
      wlClaimed: 0,
      // Contract
      isActive: false,
      isPresaleActive: false,
      currentSupply: 0,
      totalTokens: 9777,
      maxSupply: 9777,
      buyLimit: 2,
      nftCount: 0,
      claimCount:0,
      whiteListMaxMint: 2,
      notAllowed: false,
      // Form data
      nftsCountToMint: 2,
      minted: false,
      isMinting: false,
      checkId:0,
      claimable:"",
      condition:false
    };
  },
  async created() {
    await this.loadWeb3();
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    rmCount() {
      if (this.nftsCountToMint > 1) {
        this.nftsCountToMint--;
      }
    },
    addcount() {
      if (this.nftsCountToMint < 5) {
        this.nftsCountToMint++;
      }
    },

    async loadWeb3() {
     
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async (accounts) => {
          await this.setWallet(accounts[0]);
        });
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        window.alert("Non-Ethereum browser detected. You should consider trying MetaMask !");
      }

      await this.loadContractData();
      setInterval(
        function () {
          this.loadContractData();
        }.bind(this),
        1000
      );
    },
    async pick(nftsCountToMint) {
      this.nftsCountToMint = nftsCountToMint;
      console.log(this.nftsCountToMint);
    },
    async loadContractData() {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();

      if (networkId !== abis.network) {
        window.alert("Please change to ethereum mainnet.");
        return;
      }

      this.abi = abis.abi;
      this.address = abis.address;
      this.addressOne = abisOne.address;

      this.contract = new web3.eth.Contract(this.abi, this.address);
      this.nftCount = await this.contract.methods.balanceOf(this.accountID).call();
      this.abiOne = abisOne.abi;
      this.contractOne = new web3.eth.Contract(this.abiOne, this.addressOne);
    
    this.claimCount = await this.contractOne.methods.unClaimed().call({
                from: this.accountID
              })
    },
    async setWallet(address) {
      this.accountID = address;
    },
    async connectWallet() {
      console.log("Connect to wallet");
      const web3js = window.web3;
      if (typeof web3js !== "undefined") {
        this.web3 = new Web3(web3js.currentProvider);
        const accounts = await window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .catch((err) => {
            alert(err.message);
          });
        await this.setWallet(accounts[0]);
        await this.loadContractData();
      } else {
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
        alert("Unable to connect to Metamask");
      }
    },
     async check() {
      console.log("Connect to wallet");
      console.log(this.checkId)
      this.condition=await this.contractOne.methods.claimed(this.checkId).call()
      this.claimable=this.condition?"C01 #"+this.checkId+ " has been claimed":"C01 #"+this.checkId+ "  is claimable"
     
      
    },
    async claim(){
      
      await this.contractOne.methods
              .claim()
              .send({
                from: this.accountID,
                value: 0,
              })
              .on("receipt", function (res) {
                window.location.href="https://twitter.com/intent/tweet?url=https://opensea.io/collection/c-01-official-collection&text=I%20claimed%20my%20C-02%20by%20holding%20C-01%20NFT!%20%F0%9F%94%A5%20%0AClaim%20it%20now!%20%40C_01_Project%0A%20%23C02%20%23NFT%0A"
                console.log("Receipt :", res);
              })
              .on("error", function (err) {
                console.log("error:" + err);
                alert("Transaction Error");

              });
              this.claimCount = await this.contractOne.methods.unClaimed().call({
                from: this.accountID
              })
    },
    //Minting Functionality
    async mint(e) {
      this.isMinting = true;
      e.preventDefault();
      this.accountBalance = await window.web3.eth.getBalance(this.accountID);
      if (this.accountID === "") {
        window.alert("Please connect wallet first!");
        this.isMinting = false;
        return;
      } else if (this.accountBalance <= this.nftPrice * this.nftsCountToMint) {
        this.isMinting = false;
        alert(`Insufficient funds`);
        return;
      }

      this.isActive = await this.contract.methods.isActive().call();
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
      console.log("isActive : ", this.isActive);
      console.log("isPresaleActive : ", this.isPresaleActive);

      if (!this.isActive) {
        this.isMinting = false;
        alert("Sale is not active yet!");
        return;
      }

      const noOfTokens = this.nftsCountToMint;
      console.log("nftPrice : ", this.nftPrice);
      if (this.isPresaleActive == true) {
        this.whiteListMaxMint = await this.contract.methods.WHITELIST_MAX_MINT().call();
        this.wlClaimed = parseInt(await this.contract.methods.whiteListClaimed(this.accountID).call());

        if (this.wlClaimed + this.nftsCountToMint > this.whiteListMaxMint) {
          alert(`Already minted ${this.wlClaimed} but max is ${this.whiteListMaxMint}`);
          this.isMinting = false;
          //this.notAllowed = true;
          return;
        }

        console.log("whiteListMaxMint : ", this.whiteListMaxMint);
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (noOfTokens > this.whiteListMaxMint) {
          alert("Buy limit for presale is : " + this.whiteListMaxMint);
          this.notAllowed = true;
        } else if (this.totalSupply >= this.totalTokens) {
          alert("Sold out!");
        } else {
          const proof = await this.GetMerkleProof(this.accountID);
          if (proof.length == 0) {
            //alert("This wallet is not Whitelisted");
            this.notAllowed = true;
          } else {
            const result = await this.contract.methods
              .mintNFTDuringPresale(noOfTokens, proof)
              .send({
                from: this.accountID,
                value: parseInt(this.nftPrice) * noOfTokens,
              })
              .on("receipt", function (res) {
                this.minted = true;
                this.isMinting = false;
                console.log("Receipt :", res);
              })
              .on("error", function (err) {
                this.isMinting = false;
                console.log("error:" + err);
                alert("Transaction Error");

              });
            this.minted = true;
            console.log("Test :", result);
          }
        }
      } else {
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (this.totalSupply >= this.currentSupply) {
          alert("Sold out!");
        } else {
          const result = await this.contract.methods
            .mintNFT(noOfTokens)
            .send({
              from: this.accountID,
              value: parseInt(this.nftPrice) * noOfTokens,
            })
            .on("receipt", function (res) {
              this.minted = true;
              this.isMinting = false;
              console.log("Receipt :", res);
            })
            .on("error", function (err) {

              this.isMinting = false;
              console.log(err);
              alert("Transaction Error");
            });
          this.minted = true;
          console.log("Test :", result);
        }
      }
      this.isMinting = false;
    },
  },
};
</script>


<style lang="scss">
/*************************************** TOPVIEW *****************************************/
.topView {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  //  min-height: 100vh;
}

.topCenter {
  min-height: 80vh;
  max-width: 100%;
  width: 100%;
  margin: auto;
  //border: 1px solid blue;
}

// .topViewImg {
//   width: 100%;
//   max-width: 1200px;
//   object-fit: contain;
//   margin: auto;
// }

.topViewImg2 {
  width: 100%;
  max-width: 1400px;
  object-fit: contain;
  margin: auto;
}

.middle {
  margin: auto;
  margin-top: -120px;
}

.topT1 {
  margin: auto;
  text-align: center;
  font-size: 50px;
  // font-weight: 600;
}

.topT2 {
  margin: auto;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .topT1 {
    font-size: 20px;
  }
  .topT2 {
    font-size: 20px;
  }

  .topViewImg2 {
    margin-top: 50px;
    object-fit: cover;
    height: 50vh;
    width: 100%;
    margin-left: 0;
    //border: 1px solid blue;
  }
}

/*************************************** CLUB *****************************************/
.club {
  background-color: #191e2b;
  box-shadow: 0px 0px 10px #191e2b;
  z-index: 100;
}

.clubCenter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //max-width: 1200px;
}

.clubLeft {
  width: 50%;
  text-align: left;
  //border: 1px solid red;
}

.clubTitle {
  // margin: auto;
  text-align: left;
  font-size: 22px;
}
.clubText {
  font-size: 16px;
}

.clubButton {
  margin-top: 50px;

  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #dcdcdc;
    // border-radius: 14px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(#dcdcdc, 15%);
    transition: all 0.3s;
    //border-radius: 14px;
    z-index: -1;
  }
  &:hover {
    // color: #fff;
    filter: none;
    transform: none;
    &:before {
      width: 100%;
    }
  }
}

.clubRight {
  width: 40%;
  //border: 1px solid red;
}

.clubImg {
  width: 100%;
  margin-top: -50px;
  margin-bottom: 50px;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .clubCenter {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-bottom: 100px;
  }
  .clubLeft {
    width: 100%;
  }
  .clubTitle {
    text-align: center;
  }
  .clubText {
    text-align: center;
  }
  .clubRight {
    width: 100%;
  }
  .clubButton {
    width: 100%;
  }
}

/*************************************** ARTIST *****************************************/
.artist {
  background-color: #1e263a;
}

.artistCenter {
  //border: 1px solid red;
  margin: auto;
}

.artistText {
  text-align: center;

  max-width: 900px;
  margin: auto;
  margin-bottom: 50px;
}

.contentArtist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
  width: 100%;
  max-width: 1400px;
  //border: 1px solid red;
}

.paw1 {
  position: absolute;
  width: 150px;
  left: 50px;
  margin-top: 300px;
  z-index: 0;
  object-fit: contain;
}

.paw2 {
  position: absolute;
  width: 150px;
  right: 50px;
  margin-top: -300px;
  z-index: 0;
  object-fit: contain;
}

.paw3 {
  position: absolute;
  width: 150px;
  left: 50px;
  margin-top: -200px;
  z-index: 0;
  object-fit: contain;
}

.paw1,
.paw2,
.paw3 {
  transition: all 100ms ease-in-out;
  &:hover {
    filter: drop-shadow(0px 0px 5px $white);
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .paw1,
  .paw2,
  .paw3 {
    display: none;
  }
}
/*************************************** BANNER *****************************************/

.banner {
  background-color: #3a4255;
}

.banner1 {
  width: 100%;
  object-fit: contain;
}

@media screen and (min-width: $layout-breakpoint-medium) {
  .showmob {
    display: none;
  }
}
@media screen and (max-width: $layout-breakpoint-medium) {
  .hidemob {
    display: none;
  }
}

/*************************************** ROADMAP *****************************************/
.roadmap {
  // background-color: #1e263a;
  justify-content: center;
  margin: auto;
  width: 100%;
  //border: 1px solid blue;
}

.roadmapCenter {
  //max-width: 1200px;
  //border: 1px solid red;
}

.roadTitle {
  font-size: 35px;
}

.roadmaap {
  margin-top: 100px;
  //border: 1px solid red;
}

.liane1 {
  position: absolute;
  //width: 20%;
  width: 400px;
  left: 0;
  margin-top: -500px;
  z-index: 0;
  object-fit: contain;
}

.liane2 {
  position: absolute;
  //width: 20%;
  width: 400px;
  right: 0;
  margin-top: 500px;
  z-index: 0;
  object-fit: contain;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .liane1,
  .liane2 {
    display: none;
  }
  .roadmaap {
    margin-top: 0px;
  }
}
/*************************************** Team *****************************************/
.team {
}

.teamTitle {
  color: $white;
  //max-width: 1200px;
  text-align: center;
  margin-bottom: 50px;
}

.contentTeam {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  //border: 1px solid red;
}

/*************************************** FAQ *****************************************/
.faq {
}

.faqCenter {
  max-width: 980px;
}

.faqTitle {
  font-size: 22px;
}

.faqCard {
  background-color: #3a4255;
  border-radius: 20px;
  padding: 50px 100px;
  width: 100%;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .faqCard {
    background-color: #3a4255;
    border-radius: 0px;
    padding: 25px 25px;
    width: 100%;
  }
}
</style>
