import Vue from "vue";
import Router from "vue-router";
import Mint from "./views/Mint.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      name: "Home",
      component: Mint,
    },
  ],
});
