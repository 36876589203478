<template>
  <div id="app">
    <!-- <Topbar /> -->
    <router-view />
    <!-- <Footer v-if="this.$route.name === 'Home'" /> -->
  </div>
</template>

<script>
// import Topbar from "@/components/Topbar";
// import Footer from "@/components/Footer";

export default {
  name: "App",
  // components: { Topbar, Footer },
};
</script>
